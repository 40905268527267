<template>
  <div>
    <div v-if="movements_with_wallet && !$apollo.loading">
      <b-card id="mh1" title="Lista de movimentações">
        <b-table
          :items="movements_with_wallet.data"
          :fields="fields"
          striped
          sticky-header
          responsive
          class="data-table"
        >
          <template #cell(session_id)="data">
            <div class="text-center">
              <a :id="'popover-target-'+data.item.id" class="see" @mouseover="getSessionInfo(data.item.session_id)">{{data.item.session_id}}</a>
              <b-popover :target="'popover-target-'+data.item.id" triggers="hover" placement="top">
                <template #title>{{lineSes.session}}</template>
                <div v-if="lineSes.session == null">
                  aguarde...
                </div>
                <div v-else>
                  {{lineSes.game.name}}
                </div>
              </b-popover>
            </div>
          </template>
          <template #cell(movement_type.code)="data">
            <span
              v-if="data.item.movement_type.code == 'GAME_CREDIT'"
              class="badge badge-success"
            >
              {{ data.item.movement_type.code }}
            </span>
            <span
              v-if="data.item.movement_type.code == 'GAME_DEBIT'"
              class="badge badge-danger"
            >
              {{ data.item.movement_type.code }}
            </span>

            <span
              v-if="data.item.movement_type.code == 'DEPOSIT'"
              class="badge badge-success"
            >
              {{ data.item.movement_type.code }}
            </span>
            <span
              v-if="data.item.movement_type.code == 'WITHDRAW'"
              class="badge badge-danger"
            >
              {{ data.item.movement_type.code }}
            </span>
            <span
              v-if="data.item.movement_type.code == 'BONUS'"
              class="badge badge-success"
            >
              {{ data.item.movement_type.code }}
            </span>
            <span
              v-if="data.item.movement_type.code == 'BONUS_CREDIT'"
              class="badge badge-success"
            >
              {{ data.item.movement_type.code }}
            </span>
            <span
              v-if="data.item.movement_type.code == 'TRANSFER'"
              class="badge badge-warning"
            >
              {{ data.item.movement_type.code }}
            </span>
            <span
              v-if="data.item.movement_type.code == 'CONVERT_BONUS'"
              class="badge badge-warning"
            >
              {{ data.item.movement_type.code }}
            </span>
            <span v-if="data.item.movement_type.code == 'FEE'" class="badge badge-danger">
              {{ data.item.movement_type.code }}
            </span>
            <span
              v-if="data.item.movement_type.code == 'ROLLBACK'"
              class="badge badge-warning"
            >
              {{ data.item.movement_type.code }}
            </span>
            <span
              v-if="data.item.movement_type.code == 'RESET_ACCOUNT'"
              class="badge badge-warning"
            >
              {{ data.item.movement_type.code }}
            </span>
            <span
              v-if="data.item.movement_type.code == 'CANCEL'"
              class="badge badge-warning"
            >
              {{ data.item.movement_type.code }}
            </span>
            <span
              v-if="data.item.movement_type.code == 'MANUAL_FIX'"
              class="badge badge-warning"
            >
              FIX Manual
            </span>

          </template>
          <template #cell(actions)="data">
            <feather-icon icon="PlusIcon" class="ml-2 text-success pointer" />
          </template>
        </b-table>
      </b-card>
      <nav v-if="!$apollo.loading" aria-label="...">
        <ul class="pagination">
          <li v-if="movements_with_wallet.paginatorInfo.currentPage - 1 > 0" class="page-item">
            <span class="page-link pointer" @click="paginate(-1)">Anterior</span>
          </li>
          <li class="page-item active">
            <span class="page-link">
              {{ movements_with_wallet.paginatorInfo.currentPage }}
            </span>
          </li>
          <li
            v-if="movements_with_wallet.paginatorInfo.currentPage < movements_with_wallet.paginatorInfo.lastPage"
            class="page-item"
          >
            <span class="page-link pointer" @click="paginate(+1)">Proxima</span>
          </li>
        </ul>
      </nav>
    </div>
    <b-card v-else id="l-container">
      <logo-loader-animation />
    </b-card>
  </div>
</template>
<script>
import {
  BTable,
  BFormCheckbox,
  BButton,
  BCard,
  BRow,
  BCol,
  BBadge,
  BCardText,
  BPopover,
  BLink,
} from "bootstrap-vue";
import LogoLoaderAnimation from "@/components/misc/LogoLoaderAnimation.vue";

import gql from "graphql-tag";
import { _ } from "vue-underscore";

export default {
  components: {
    BTable,
    BButton,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BCardText,
    BBadge,
    BLink,
    underscore: _,
    BPopover,
    LogoLoaderAnimation,
  },
  props: ["userWallets", "uWall","userId"],
  data() {
    return {
      fields: [
        { key: "id", label: "id", formatter: "cellDecoupe" },
        { key: "wallet_id", label: "Carteira", formatter: "walletName" },
        { key: "session_id", label: "ID Sessão" },
        { key: "external_transaction_id", label: "Trans. ID" },
        {
          key: "wallet_snapshot.start_balance",
          label: "saldo previo",
          formatter: "currency",
        },
        { key: "value", label: "Valor", formatter: "currency" },
        {
          key: "wallet_snapshot.final_balance",
          label: "saldo final",
          formatter: "currency",
        },
        { key: "movement_type.code", label: "Moviment" },
        { key: "description", label: "Desc" },
        { key: "created_at", label: "Data", formatter: "dateFormatter" },
      ],
      movements_with_wallet: null,
      pageToSearch: 1,
      lineSes:{
        session:null,
        game:{
          name:null
        }
      },
      lastSearchedSession: ''
    };
  },
  apollo: {
    movements_with_wallet: {
      query: gql`
        query movements_with_wallet($containsWalletsIds: [String!], $offset: Int, $user_id: String) {
          movements_with_wallet(first: 50, user_id: $user_id, containsWalletsIds: $containsWalletsIds, page: $offset) {
            data {
              id
              code
              wallet_id
              invoice_id
              session_id
              external_transaction_id
              movement_type_id
              value
              description
              movement_type {
                code
              }
              created_at
              wallet_snapshot {
                start_balance
                final_balance
              }
            }
            paginatorInfo {
              currentPage
              lastPage
            }
          }
        }
      `,
      client: "walletClient",
      variables() {
        return {
          containsWalletsIds: this.userWallets,
          user_id: this.userId,
          offset: this.pageToSearch,
        };
      },
    },
    all_wallet_types: {
      query: gql`
        query {
          all_wallet_types {
            id
            code
          }
        }
      `,
      client: "walletClient",
    },
  },
  created() {},
  methods: {
    walletName(val) {
      const w = this.uWall.data.filter((w) => w.id == val)[0];
      return this.all_wallet_types.filter((a) => a.id == w.wallet_type_id)[0].code;
    },
    currency(value) {
      if (value != null) {
        return value.toLocaleString("pt-br", { style: "currency", currency: "BRL" });
      }
      return value;
    },
    paginate(eV) {
      this.pageToSearch += eV;
      this.$apollo.queries.movements_with_wallet.refetch();
    },
    dateFormatter(value) {
      return this.$datter.convert(value);
    },
    showBox(w){
     this.lineSes = w;
    },
   getSessionInfo(wid){
      if(wid != this.lastSearchedSession){
        this.lastSearchedSession = wid;
        this.lineSes.session = null; 
      }else{
        return;
      }
      const sesInfo = {};
      this.$gpas.get('session-info',{
      withCredentials: false,
      params:{
        wallet_session:wid
      }
      }).then((res)=>{
        sesInfo.session = res.data.game_session_uid;
        if(res.data.game_id){
          this.$apollo.query({
            query: gql`query($value: String!){
              Game(provider_play_code:$value) {
                name,
                provider{
                  name
                }
              }
            }`,
            variables:{
              value: res.data.game_id
            },
            client: "gameClient"
          }).then((data) => {
            console.log(data)
            if(data){
              sesInfo.game = data.data.Game
              this.showBox(sesInfo);
            }
          }).catch((error) => {
            console.error(error)
          })
        }
      }).catch((error) => {
        
      })
    }
  },
};
</script>

<style lang="scss">
#l-container {
  height: 400px;
  width: 100%;
  position: relative;
  svg {
    height: 200px;
  }
}
.floater {
  /* position: absolute; */
  z-index: 99;
}
.data-table tbody {
  padding-bottom: 30px;
}
#export-button {
  position: absolute;
  top: 10px;
  right: 10px;
}
#cardo {
  position: relative;
}
#mh1 {
  max-height: 400px;
  overflow: scroll;
  min-height: 400px;
}
th {
  div {
    color: #d0d2d6;
  }
}
.see{
  color:#A9A2F6;
}
</style>
