<template>
  <b-row v-if="movements">
    <b-col lg="3" md="6">
      <b-media no-body class="mb-3">
        <b-media-aside class="mr-2">
          <b-avatar size="48" variant="success">
            <feather-icon size="24" icon="DollarSignIcon" />
          </b-avatar>
        </b-media-aside>
        <b-media-body class="my-auto">
          <h4 class="font-weight-bolder mb-0">R$ 0,00</h4>
          <b-card-text class="font-small-3 mb-0">
            Pendencias atuais
          </b-card-text>
        </b-media-body>
      </b-media>
    </b-col>
    <b-col lg="3" md="6">
      <b-media no-body class="mb-3">
        <b-media-aside class="mr-2">
          <b-avatar size="48" variant="danger">
            <feather-icon size="24" icon="DollarSignIcon" />
          </b-avatar>
        </b-media-aside>
        <b-media-body class="my-auto">
          <h4 class="font-weight-bolder mb-0">R$ -850,00</h4>
          <b-card-text class="font-small-3 mb-0">
            Rentabilidade últ. 60D
          </b-card-text>
        </b-media-body>
      </b-media>
    </b-col>
    <b-col lg="3" md="6">
      <b-media no-body class="mb-3">
        <b-media-aside class="mr-2">
          <b-avatar size="48" variant="success">
            <feather-icon size="24" icon="ArrowDownIcon" />
          </b-avatar>
        </b-media-aside>
        <b-media-body class="my-auto">
          <h4 class="font-weight-bolder mb-0">
            {{
              movements.data.filter((d) => d.movement_type_id == "1").length > 0
                ? movements.data.filter((d) => d.movement_type_id == "1").length
                : "Sem Info"
            }}
          </h4>
          <b-card-text class="font-small-3 mb-0">
            Depositos confirmados
          </b-card-text>
        </b-media-body>
      </b-media>
    </b-col>
    <b-col lg="3" md="6">
      <b-media no-body class="mb-3">
        <b-media-aside class="mr-2">
          <b-avatar size="48" variant="danger">
            <feather-icon size="24" icon="ArrowUpIcon" />
          </b-avatar>
        </b-media-aside>
        <b-media-body class="my-auto">
          <h4 class="font-weight-bolder mb-0">Sem</h4>
          <b-card-text class="font-small-3 mb-0">
            Saques nos úlitmos 60 dias.
          </b-card-text>
        </b-media-body>
      </b-media>
    </b-col>
  </b-row>
</template>


<script>
import {
   BCard, BRow, BCol, BCardText, BAvatar,BMediaBody,BMediaAside,BMedia
} from 'bootstrap-vue'
// import gql from 'graphql-tag'

export default {
  components: {
    BRow,
    BCard,
    BCol,
    BCardText,
    BAvatar,BMediaBody,BMediaAside,BMedia,

  },
  props:['movements'],
  data() {
    return {
    }
  },
  methods:{
    
  },
}
</script>

<style>
</style>