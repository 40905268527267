<template>
  <div>
    <b-sidebar
      id="sidebar-change-btag"
      backdrop-variant="primary"
      backdrop
      v-model="visibility"
      :right="true"
      title="EDIÇÃO DE BTAG"
    >
      <b-card>
        <div class="form-block">
          <hr data-content="Preencha abaixo" class="hr-text">
          <b-form-group>
            <div class="mt-2 d-flex text-center">
              <b-form-input icon-no-border @keydown.space.prevent placeholder="BTAG" class="input-rounded-full" v-model="newBtag" />
            </div>
          </b-form-group>
        </div>
        <b-alert
          :show="dismissCountDown"
          fade
          variant="warning"
          @dismiss-count-down="countDownChanged"
          class="p-1"
        >
          {{message}}
        </b-alert>
        <div class="mt-2">
          <b-button block @click="alterBtagTransaction()" variant="gradient-primary" >Alterar</b-button>
        </div>
      </b-card>
      <div class="loading-bg" v-if="loading">
        <div class="loading">
          <div class="effect-1 effects"></div>
          <div class="effect-2 effects"></div>
          <div class="effect-3 effects"></div>
        </div>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar,
  BCard,
  BButton,
  BRow,
  BFormInput,
  BFormGroup,
  BCardText,
  BSpinner,
  BAlert
  } from 'bootstrap-vue'
import gql from 'graphql-tag'
export default {
  props:['bTag'],
  components: {
    BSidebar,
    BButton,
    BCard,
    BRow,
    BFormInput,
    BFormGroup,
    BCardText,
    BSpinner,
    BAlert
  },
  data() {
    return {
      visibility:false,
      loading:false,
      newBtag:null,
      dismissSecs:2,
      dismissCountDown:0,
      showDismissibleAlert: false,
      message: ''
    }
  },
  methods:{
    toogleV(){
        this.visibility = !this.visibility
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert(){
      this.dismissCountDown = this.dismissSecs
    },
    alterBtagTransaction(){
      if(this.bTag){
        this.removeBtag(this.bTag.id);
      }
      this.createBtag();
      this.informPlat();
    },
    createBtag(){
      this.$apollo.mutate({
        mutation: gql`mutation (
          $user_id: ID!,
          $type: String,
          $value: String,
          $status: String,
          $audited_by: String,
        ) {
          createUserDocument(
            user_id: $user_id,
            type: $type,
            value: $value,
            status: $status,
            audited_by: $audited_by,
          ){
            id
          }
        }`,
        variables: {
          user_id: this.$route.params.clientId,
          type: "CLICKID",
          value: this.newBtag,
          status: "VALIDATED",
          audited_by: "CAS: " + JSON.parse(window.localStorage.getItem('userData')).fullName,
        },
        client: "identityClient"
      }).then((data) => {
        this.loading = false;
        this.toogleV();
        this.$emit('refreshUserData',true)
      }).catch((error) => {
        console.error(error)
      });
    },
    removeBtag(docId){
      this.docId = docId;
      this.$apollo.mutate({
          mutation: gql`mutation (
            $id: ID!,
          ) {
            deleteUserDocument(
              id: $id,
            ){
              id
            }
          }`,
          variables: {
            id: this.docId,
          },
          client: "identityClient"
        }).then((data) => {
        }).catch((error) => {
        })
    },
    informPlat(){
      let token = config.TRAFFIC_TOKEN;
      let preffix = config.USER_PREFFIX;
      let userId = this.$route.params.clientId;
      let finalId = preffix+userId;

      let platUrl = config.POSTBACK_URL+'?type=signup&clickid='+this.newBtag+'&playerid='+finalId+'&key='+token;

      fetch(platUrl,
        { method: 'GET', mode: 'no-cors' }).then((res) => {
        console.log(res);
        console.log('PLAT INFORMED')
        // this.$apollo.queries.rounds_by_pk.refetch()
      })
    }
  }
}
</script>

<style>
  .b-sidebar-right{
      background: #161d31!important;
      width: 450px;
  }
  #sidebar-change-name___title__{
    text-align: center;
    width: 100%;
    display: block;
  }
  .hr-text {
    position: relative;
    border: none;
    height: 1px;
    background: #999;
}

.hr-text::before {
    content: attr(data-content);
    display: inline-block;
    background: #fff;
    font-weight: bold;
    font-size: 1rem;
    color: #999;
    border-radius: 30rem;
    padding: 0.2rem 1rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.pointer label{
  cursor: pointer;
}
.loading-bg{
  background: rgb(16,170,228, 50%);
  width: 100%;
  height: 100%;
  top: 0;
  position: absolute;
  z-index: 3;
}
</style>