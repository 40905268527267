<template>
  <div id="user-related-scope">  
    <b-modal id="user-related-modal" title="Adicionar relacionado" ok-only>
      <b-card title="Busque um cliente 🔍" class="mb-0">
        <b-card-text>No campo abaixo busque por: Email, Nome, Nickname</b-card-text>
        <b-form-input 
          icon-no-border  
          @keyup.enter.native="searchUser()" 
          placeholder="Digite e pressione ENTER" 
          v-model="searchQuery" 
          class="input-rounded-full" 
          icon="icon-search" 
          icon-pack="feather"
          :state="docState"
          aria-describedby="input-live-help input-live-feedback"
          id="live-query"
        />
        <b-form-invalid-feedback id="input-live-feedback">
          Pelo menos 3 letras
        </b-form-invalid-feedback>
      </b-card>
      <general-clients-list @addRelation="fillRelatedUser" :searchedUsers="queryResult" :contextActions="['add-relation']" :customFields="fields"/>
      <b-card v-if="relatedToAdd" title="Adicionar relação" class="mb-0">
        <p class="label">Login: <b>{{relatedToAdd.username}}</b></p>
        <p class="label">Nickname: <b>{{relatedToAdd | userDocument("NICKNAME")}}</b></p>
        <p class="label">Email: <b>{{relatedToAdd | userDocument("EMAIL")}}</b></p>
         <b-form-select v-model="selectedRelationType" size="lg" class="mb-1">
          <b-form-select-option :value="null">Tipo de relação</b-form-select-option>
          <b-form-select-option v-for="type in all_relation_types"   
                                v-bind:data="type"
                                v-bind:key="type.code" :value="type.id">{{type.code}}</b-form-select-option>
        </b-form-select>
        <alert-toast :message="this.warningMessage" :variant="this.messageVariant" ref="warning-toast"/>
        <b-button class="act-button " variant="gradient-primary" @click="addUserRelation()">Adicionar relação</b-button>
      </b-card>
    </b-modal>
  </div>
</template>
<script>
import { BCard,BRow, BCardText, BLink,BButton, BFormInput, BFormInvalidFeedback, BFormSelect, BFormSelectOption } from 'bootstrap-vue'
import GeneralClientsList from "@/components/clients/GeneralClientsList.vue"
import AlertToast from "@/components/misc/AlertToast.vue"
import gql from 'graphql-tag'
import {_} from 'vue-underscore';

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BFormInput,
    GeneralClientsList,
    BFormInvalidFeedback,
    underscore: _,
    BButton,
    BRow,
    AlertToast,
    BFormSelect, BFormSelectOption

  },
  props:['alreadyRelated'],
  data () {
    return {
      fields: ['id', {key:'username', label: 'Nickname'},{key:'fullname', label: 'Nome',formatter: 'cellDecoupe'},  {key:'email', label: 'Email', formatter: 'cellDecoupe'}, { key: 'actions', label: 'Ações' }],
      searchQuery: '',
      currentPage: 1,
      searchResults: [],
      queryResult:null,
      relatedToAdd:null,
      warningMessage:null,
      messageVariant:'warning',
    }
  },
  apollo:{
    all_relation_types: {
      query: gql`query{
        all_relation_types{
          id,
          code
        }
      }`,
      client: 'identityClient',
    },
  },
  computed: {
    docState() {
      return this.searchQuery.length > 2 ? true : false
    }
  },
  methods:{
    searchUser(){
      if(this.searchQuery == '' || !document.getElementById('live-query').getAttribute("aria-invalid")){
        this.$apollo.query({
          query: gql`query($value: String!){
            user_documents_by_value(
              value : $value,
              first:100
            ){
              data{
                value,
                user{
                  id,
                  username,
                  status,
                  created_at,
                  documents{
                    id,
                    type,
                    value
                  }
                },
              }
            }
          }`,
          variables:{
            value: '%'+this.searchQuery+'%'
          },
          client: "identityClient"
        }).then((data) => {
          // Result
          this.queryResult = data.data.user_documents_by_value.data
          this.queryResult = _.pluck(this.queryResult, 'user');
        }).catch((error) => {
          console.error(error)
        })
      }
    },
    fillRelatedUser(e){
      this.relatedToAdd = e;
    },
    addUserRelation(){
      if(this.selectedRelationType == null){
        this.warningMessage = 'Selecione o tipo de relação!';
        this.$refs['warning-toast'].showAlert();
        return null;
      }
      if(this.alreadyRelated != null){
        let checkAlready = this.alreadyRelated.filter(r => r.id == this.relatedToAdd.id);
        if(checkAlready.length > 0){
          this.warningMessage = 'Usuário já relacionado!';
          this.$refs['warning-toast'].showAlert();
          return null;
        }else{
          this.mutateUserRelation();
        }
      }
    },
    mutateUserRelation(){
      this.$apollo.mutate({
        mutation: gql`mutation($user_id: ID!,$with_user_id: ID!,$relation_type_id: ID!){
          createUserRelation(
            user_id : $user_id,
            with_user_id : $with_user_id,
            relation_type_id : $relation_type_id,
          ){
            id
          }
        }`,
        variables:{
          user_id: this.$route.params.clientId,
          with_user_id: this.relatedToAdd.id,
          relation_type_id:this.selectedRelationType,
        },
        client: "identityClient"
      }).then((data) => {
        this.$emit('refreshUserData', true);
        this.warningMessage = 'Usuário adicionado com sucesso!';
        this.messageVariant = 'success';
        this.$refs['warning-toast'].showAlert();

      }).catch((error) => {
        console.error(error)
      })
    }
  },
  created(){
  }
}
</script>
<style lang="scss">
#user-related-modal{
  .modal-dialog {
    min-width: fit-content;
    
  }
  .modal-content{
    width:900px;
  }
  .f-left{
    float:left;
  }
}

@media screen and (max-width: 767px) {
  #user-related-modal{
    padding:0;
    #modal-dialog{
      padding: 0;
      margin: 0;
    }
    .modal-content{
      width:390px;
    }
  }
}
</style>
