<template>
  <div id="user-related-scope">  
    <b-modal id="user-group-modal" title="Adicionar relacionado" ok-only>
     <b-card title="Busque um grupo 🔍">
        <b-card-text>No campo abaixo busque por nome</b-card-text>
        <b-form-input 
          icon-no-border  
          @keyup.enter.native="searchUser()" 
          placeholder="Digite e pressione ENTER" 
          v-model="searchQuery" 
          class="input-rounded-full" 
          icon="icon-search" 
          icon-pack="feather"
          :state="docState"
          aria-describedby="input-live-help input-live-feedback"
          id="live-query"
        />
        <b-form-invalid-feedback id="input-live-feedback">
          Pelo menos 3 letras
        </b-form-invalid-feedback>
      </b-card>
      <general-groups-list @addUserOnGroup="addGroup" :addInfo="alreadyAdded" @removeUserFromGroup="removeGroup" :searchedGroups="queryResult" :contextActions="'manageMembers'"/>
      <alert-toast :message="this.warningMessage" :variant="this.messageVariant" ref="warning-toast"/>
    </b-modal>
  </div>
</template>
<script>
import { BCard,BRow, BCardText, BLink,BButton, BFormInput, BFormInvalidFeedback, BFormSelect, BFormSelectOption } from 'bootstrap-vue'
import GeneralGroupsList from "@/components/groups/GeneralGroupsList.vue"

import AlertToast from "@/components/misc/AlertToast.vue"
import gql from 'graphql-tag'
import {_} from 'vue-underscore';

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BFormInput,
    GeneralGroupsList,
    BFormInvalidFeedback,
    underscore: _,
    BButton,
    BRow,
    AlertToast,
    BFormSelect, BFormSelectOption

  },
  props:['alreadyAdded'],
  data () {
    return {
      fields: ['id', {key:'username', label: 'Nickname'},{key:'fullname', label: 'Nome',formatter: 'cellDecoupe'},  {key:'email', label: 'Email', formatter: 'cellDecoupe'}, { key: 'actions', label: 'Ações' }],
      searchQuery: '',
      currentPage: 1,
      searchResults: [],
      queryResult:null,
      relatedToAdd:null,
      warningMessage:null,
      messageVariant:'warning',
    }
  },
  apollo:{
    all_relation_types: {
      query: gql`query{
        all_relation_types{
          id,
          code
        }
      }`,
      client: 'identityClient',
    },
  },
  computed: {
    docState() {
      return this.searchQuery.length > 2 ? true : false
    }
  },
  methods:{
    searchUser(){
      if(this.searchQuery == '' || !document.getElementById('live-query').getAttribute("aria-invalid")){
        this.$apollo.query({
          query: gql`query($value: String!){
            user_groups_by_name(
              name : $value,
              status: "ACTIVE"
            ){
              id,
              name,
              status,
              members_count,
              created_at
            }
          }`,
          variables:{
            value: '%'+this.searchQuery+'%'
          },
          client: "identityClient"
        }).then((data) => {
          this.queryResult = data.data.user_groups_by_name
        }).catch((error) => {
          console.error(error)
        })
      }
    },
    fillGroup(e){
      this.group = e;
    },
    addGroup(g){
      let checkAlready = this.alreadyAdded.filter(r => r.id == g.g_id);
      if(checkAlready.length > 0){
        this.warningMessage = 'Usuário já está no grupo!';
        this.$refs['warning-toast'].showAlert();
        return null;
      }else{
        this.fillGroup(g.g_id);
        this.mutateUserGroupRelation();
        this.$emit('refreshGroups', g.g_arr);
      }
    },
    removeGroup(g){
      this.fillGroup(g.g_id);
      this.mutateRemoveUserGroupRelation();
      this.$emit('refreshGroups', g.g_arr);

    },
    mutateRemoveUserGroupRelation(){
      this.$apollo.mutate({
        mutation: gql`mutation($user_id: ID!,$group_id: ID!){
          removeUserGroupRelationship(
            user_id : $user_id,
            group_id : $group_id,
          ){
            id
          }
        }`,
        variables:{
          user_id: this.$route.params.clientId,
          group_id: this.group,
        },
        client: "identityClient"
      }).then((data) => {
        this.warningMessage = 'Grupo removido com sucesso!';
        this.messageVariant = 'success';
        this.$refs['warning-toast'].showAlert();
      }).catch((error) => {
        console.error(error)
      })
    },
    mutateUserGroupRelation(){
      this.$apollo.mutate({
        mutation: gql`mutation($user_id: ID!,$group_id: ID!){
          createUserGroupRelationship(
            user_id : $user_id,
            group_id : $group_id,
          ){
            id
          }
        }`,
        variables:{
          user_id: this.$route.params.clientId,
          group_id: this.group,
        },
        client: "identityClient"
      }).then((data) => {
        this.warningMessage = 'Grupo adicionado com sucesso!';
        this.messageVariant = 'success';
        this.$refs['warning-toast'].showAlert();
      }).catch((error) => {
        console.error(error)
      })
    },
  },
  created(){
  }
}
</script>
<style lang="scss">
#user-group-modal{
  .modal-dialog {
    min-width: fit-content;
    
  }
  .modal-content{
    width:900px;
  }
  .f-left{
    float:left;
  }
}

@media screen and (max-width: 767px) {
  #user-related-modal{
    padding:0;
    #modal-dialog{
      padding: 0;
      margin: 0;
    }
    .modal-content{
      width:390px;
    }
  }
}
</style>
