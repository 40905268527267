<template>
  <div>
    <b-table
      :items="orders"
      :fields="fields"
      striped
      responsive
      class="data-table"
    >
      <template #cell(actions)="data">
        <feather-icon
          icon="EditIcon"
          class="mr-50 text-primary pointer"
          @click="emitSidebar(data.item)"
        />
      </template>
      <template #cell(status)="data">
        <b-badge :variant="status[1][data.value]">
          {{ status[0][data.value] }}
        </b-badge>
      </template>
      <template #cell(target_bank.owner_name)="data">
        <a :href="'/client-single/'+data.item.user_id">
          {{ data.value }}
        </a>
      </template>
    </b-table>
  </div>
</template>

<script>
import {
  BTable, BCard, BRow, BCol, BBadge,
} from 'bootstrap-vue'

export default {
  components: {
    BTable,
    BCard,
    BRow,
    BCol,
    BBadge,
  },
  props: ['orders', 'contextActions'],
  data() {
    return {
      fields: ['id', { key: 'amount', label: 'Valor', formatter: 'currency' }, { key: 'target_bank.owner_name', label: 'Solicitante' }, { key: 'provider.code', label: 'Método' }, { key: 'created_at', label: 'Aberto em', formatter: 'dateFormatter' }, { key: 'tags', label: 'Info Adicional', formatter: 'pendingInfo' }, { key: 'updated_at', label: 'Atualizado em', formatter: 'dateFormatter' }, { key: 'status', label: 'Status' }],
      items: [],
      status: [{
        CONFIRMED: 'Liberado', PENDING: 'Aguardando pg', CONFIRMED_WITH_OBSERVATION: 'Lib. Confiança', CANCELED: 'Cancelado', PROCESSING: 'Processando',
        NOT_AUTHORIZED: 'No Autorizado', UNDER_ANALYSIS: 'En Analisis',
        VERIFIED_BY_SUP2: 'Verificado por Soporte2',
      },
      {
        NOT_AUTHORIZED: 'danger',
        UNDER_ANALYSIS: 'warning',
        VERIFIED_BY_SUP2: 'warning',

        CONFIRMED: 'success', PENDING: 'info', CONFIRMED_WITH_OBSERVATION: 'warning', CANCELED: 'danger', PROCESSING: 'primary',
      }],
    }
  },
  computed: {
    processedOrders() {
      // Realizar cualquier procesamiento o manipulación necesaria en los datos de 'orders'
      return this.orders
    },
  },
  watch: {
    orders() {
      console.log('new orders', this.orders)
      this.items = this.orders
    },
  },
  created() {
    if (this.contextActions != null) {
      this.fields.push({ key: 'actions', label: 'Ações' })
    }
  },
  methods: {
    emitSidebar(transaction) {
      this.$emit('emitSidebar', transaction)
    },
    currency(value) {
      return value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
    },
    pendingInfo(value) {
      let val = value.filter(v => v.key == 'cancel_reason')
      if (val.length <= 0) {
        val = value.filter(v => v.key == 'RELEASE_COMMENT')
      }
      return val.length > 0 ? val[0].value : 'Sem info'
    },
    dateFormatter(value) {
      return this.$datter.convert(value)
    },
  },
}
</script>

<style>
  .floater{
    /* position: absolute; */
    z-index: 99;
  }
  .data-table tbody{
    padding-bottom: 30px;
  }
</style>
