<template>
  <div id="fall">
    <b-card-actions
      ref="cardNotes"
      title="Notas do usuário"
      action-collapse
      class="w-100"
    >
      <div id="canvas">
        <div id="board">
          <ul>
            <li
              v-for="note in notes"
              :class="note.priority.toLowerCase()"
            >
              <span class="wrapper">
                <h6>{{ note.title }} <span
                  class="deleter"
                  @click="deleteNote(note.id)"
                ><feather-icon
                  class="mr-1"
                  icon="TrashIcon"
                /></span></h6>
                <p>{{ note.content }}</p>
                <b class="support-b">#{{ formattedDate(note.created_at) }}</b>
                <p class="support-c">{{ note.created_by }}</p>

              </span>
            </li>
          </ul>
        </div>
      </div>
    </b-card-actions>
  </div>
</template>
<script>
import {
  BRow, BCol, BCardText, BAvatar, BMediaBody, BMediaAside, BMedia,
} from 'bootstrap-vue'
import BCardActionsContainer from '@core/components/b-card-actions/BCardActionsContainer.vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'

import gql from 'graphql-tag'

export default {
  components: {
    BRow,
    BCardActions,
    BCardActionsContainer,
    BCol,
    BCardText,
    BAvatar,
    BMediaBody,
    BMediaAside,
    BMedia,
    gql,
    BCardActionsContainer,

  },
  props: ['notes'],
  data() {
    return {
    }
  },
  methods: {
    formattedDate(realtime) {
      const fechaUTC = new Date(`${realtime.replace(' ', 'T')}Z`)
      return `${fechaUTC.getDate()} ${fechaUTC.getMonth()+1} ${fechaUTC.getFullYear()} ${fechaUTC.getHours()}:${fechaUTC.getMinutes()}:${fechaUTC.getSeconds()}`
    },
    deleteNote(id) {
      this.$refs.cardNotes.showLoading = true
      this.$apollo.mutate({
        mutation: gql`mutation (
          $id: ID!
        ) {
          deleteUserNote(
            id: $id,
          ){
            id
          }
        }`,
        variables: {
          id,
        },
        client: 'identityClient',
      }).then(data => {
        // Result
        this.$emit('refreshNotes', true)
        this.$refs.cardNotes.showLoading = false
      }).catch(error => {
        console.error(error)
      })
    },
  },
}
</script>
<style>
  .deleter{
    cursor:pointer;
    position: absolute;
    right: 0;
  }
  #canvas{
    min-width: 650px;
  }
  #board {
    margin: 20px auto;
    font-family: 'Lato';
    /* background:#666; */
    color:#fff;
  }

  #board *{
    margin:0;
    padding:0;
  }
  #board .support-b{
    position: absolute;
    bottom: 10px;
    font-family: 'Nanum Pen Script';
    font-size: 1.4rem;
  }
  #board .support-c{
    position: absolute;
    bottom: 30px;
    font-family: 'Nanum Pen Script';
    font-size: 1.4rem;
  }
  #board h6 {
    font-weight: bold;
    font-size: 1.5rem;
    font-family: Arial;
    color:#000;
    position:relative;
  }

  #board p {
    font-size: 2rem;
    font-family: 'Nanum Pen Script';
    font-weight: normal;
    margin-top: 10px;
  }

  #board ul,li{
    list-style:none;
  }
  #board ul{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  #board ul li .wrapper{
    color:#000;
    background:#ffc;
    display:block;
    height:18em;
    width:18em;
    padding:1em;
    box-shadow: 5px 5px 7px rgba(33,33,33,.7);
    transform: rotate(-6deg);
    transition: transform .15s linear;
  }
  #board ul li.high .wrapper{
    background:#f9b0b0;
  }
  #board ul li{
    margin:1em;
  }
  #board ul li:nth-child(even) .wrapper{
  transform:rotate(4deg);
  position:relative;
  top:5px;
  }
  #board ul li:nth-child(3n) .wrapper{
    transform:rotate(-3deg);
    position:relative;
    top:-5px;
  }
  #board ul li:nth-child(5n) .wrapper{
    transform:rotate(5deg);
    position:relative;
    top:-10px;
  }
  #board ul li .wrapper:hover, #board ul li .wrapper:focus{
    box-shadow:10px 10px 7px rgba(0,0,0,.7);
    transform: scale(1.25);
    position:relative;
    z-index:5;
  }
  @media screen and (max-width: 767px) {
    #fall{
      overflow:hidden;
    }
    #board ul{
      display: block;
      overflow: hidden;
      margin-left:20px

    }
  }
</style>
