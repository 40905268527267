<template>
  <div>
    <b-table
      :items="orders"
      :fields="fields"
      striped
      responsive
      class="data-table"
    >
      <template #cell(actions)="data">
        <feather-icon
          icon="EditIcon"
          class="mr-50 text-primary pointer"
          @click="emitSidebar(data.item)"
        />
      </template>
      <template #cell(status)="data">
        <b-badge :variant="status[1][data.value]">
          {{ status[0][data.value] }}
        </b-badge>
      </template>
    </b-table>
  </div>
</template>

<script>
import {
  BTable, BCard, BRow, BCol, BBadge
} from 'bootstrap-vue'
export default {
  components: {
    BTable,
    BCard,
    BRow,
    BCol,
    BBadge,
  },
  props:['orders'],
  data() {
    return {
      fields: ['id', {key:'amount', label: 'Valor', formatter: 'currency'},  {key:'provider.code', label: 'Método'},  {key:'created_at', label: 'Aberto em', formatter:'dateFormatter'},  {key:'tags', label: 'Info Adicional', formatter: 'pendingInfo'}, {key:'updated_at', label: 'Atualizado em', formatter:'dateFormatter'}, { key: 'status', label: 'Status' }, { key: 'actions', label: 'Ações' }],
      items: [],
      status: [{
        'CONFIRMED': 'Liberado', "PENDING": 'Aguardando pg', 'CONFIRMED_WITH_OBSERVATION': 'Lib. Confiança', 'CANCELED': 'Cancelado', 
      },
      {
        'CONFIRMED': 'success', "PENDING": 'info', 'CONFIRMED_WITH_OBSERVATION': 'warning', 'CANCELED': 'danger', 
      }],
    }
  },
  methods:{
    emitSidebar(deposit){
      this.$emit('openSidebar', deposit,'depositSidebar')
    },
    currency(value){
      return value.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
    },
    pendingInfo(value){
      let val = value.filter(v => v.key == 'SOURCE')
      return val.length > 0 ? val[0].value : 'Sem info'
    },
    dateFormatter(value){
      return this.$datter.convert(value);
    }
  },
}
</script>

<style>
  .floater{
    /* position: absolute; */
    z-index: 99;
  }
  .data-table tbody{
    padding-bottom: 30px;
  }
</style>