<template>
  <div v-if="message!=null">
    <b-alert
      :show="dismissCountDown"
      fade
      :variant="variant"
      @dismiss-count-down="countDownChanged"
      class="p-1"
    >
      {{message}}
    </b-alert>
  </div>
</template>

<script>
import {
  BAlert
  } from 'bootstrap-vue'
export default {
  components: {
    BAlert,
  },
  props: ['message','variant'],
  data() {
    return {
      dismissSecs:2,
      dismissCountDown:0,
      showDismissibleAlert: false,
    }
  },
  methods:{
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert(){
      this.dismissCountDown = this.dismissSecs
    },
  },
}
</script>