<template>
  <div id="transmodal">
    <b-card title="Transações do usuário">
      <b-row class="text-center">
        <b-col class="select-label ">
          <p class="w-100 m-0">Selecione o periodo:</p>
        </b-col>
        <b-col class="datepick">
          Inicial:
          <b-form-datepicker
            v-model="from"
            locale="pt-br"
            :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit'}"
          />
        </b-col>
        <b-col class="datepick">
          Final:
          <b-form-datepicker
            v-model="to"
            locale="pt-br"
            :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit'}"
          />
        </b-col>
        <!-- <b-button class="act-button mt-4" @click="retriveTransactions" variant="gradient-success">Buscar</b-button> -->
      </b-row>
      <b-row id="finance-indicators" class="mt-5 text-center" v-if="transactions">
        <b-col class="text-success" v-if="transactions.sumOfDeposits">
          <p>Total em depósitos:</p>
          <span>{{ transactions.sumOfDeposits | brl }}</span>
        </b-col>
        <b-col v-else>
          <p>Sem depósitos no período:</p>
        </b-col>
        <b-col class="text-warning" v-if="transactions.sumOfWithdrawals">
          <p>Total em saques:</p>
          <span>{{ transactions.sumOfWithdrawals | brl }}</span>
        </b-col>
        <b-col v-else>
          <p>Sem saques no período:</p>
        </b-col>
        <b-col v-if="transactions.sumOfWithdrawals || transactions.sumOfDeposits" :class="transactions.sumOfWithdrawals > transactions.sumOfDeposits ? 'text-danger' : 'text-success'">
          <p>Resultado:</p>
          <span>{{ transactions.sumOfDeposits - transactions.sumOfWithdrawals | brl}}</span>
        </b-col>
        <b-col v-else>
          <p>Sem saques no período:</p>
        </b-col>
      </b-row>
      <b-row id="dep-list" class="mt-5 text-center" v-if="transactions">
        <h5 class="w-100 mb-2 ">Depósitos do período</h5>
        <deposits-list class="w-100" :orders="transactions.deposits" :noTaker="true"/>
      </b-row>
      <b-row id="with-list" class="mt-5 text-center" v-if="transactions">
        <h5 class="w-100 mb-2">Saques do período</h5>
        <withdraws-list class="w-100" :orders="transactions.withdrawals"/>
      </b-row>
      
    </b-card>
  </div>
</template>

<script>
import {
  BTable, BCard, BRow, BCol, BBadge,BButton,BFormDatepicker
} from 'bootstrap-vue'
import gql from 'graphql-tag'
import DepositsList from "@/components/transactions/DepositsList.vue"
import WithdrawsList from "@/components/transactions/WithdrawsList.vue"

export default {
  components: {
    BTable,
    BCard,
    BRow,
    BCol,
    BBadge,
    BButton,
    BFormDatepicker,
    DepositsList,
    WithdrawsList,
    gql
  },
  props:[],
  data() {
    return {
      from: new Date().toISOString().split('T')[0],
      to: new Date(new Date().getTime() + (24 * 60 * 60 * 1000)).toISOString().split('T')[0],
      transactions:null,
    }
  },
  watch:{
    from: function(){
      this.retriveTransactions()
    },
    to: function(){
      this.retriveTransactions()
    }
  },
  methods:{
    retriveTransactions(){
      this.$apollo.query({
          query: gql`query($status:String, $from: Mixed, $to: Mixed, $userId:ID){
            orders(first:3000,user_id:$userId, status:$status , where: {column:CREATED_AT, operator:GTE, 
            value: $from , AND:{column:CREATED_AT, operator:LTE, value: $to}}){
              data{
                id,
                order_type,
                user_id,
                amount,
                status,
                created_at,
                updated_at,
                target_bank{
                  bank_name,
                  owner_name,
                  account,
                  agency
                },
                tags{
                  key,
                  value
                },
                provider{
                  code
                }
              },
            }
          }`,
          variables:{
            status: "CONFIRMED",
            userId: this.$route.params.clientId,
            from: this.from,
            to: this.to,
          },
          client: 'cashioClient',
        }).then((data) => {
          this.transactions = this.split(data.data.orders.data);
        }).catch((error) => {
          console.error(error)
        })
    },
    split(transactions) {
      return transactions.reduce(
        (result, transaction) => {
          const category = transaction.order_type === 'DEPOSIT' ? 'deposits' : 'withdrawals';
          result[category].push(transaction);
          result[`sumOf${category.charAt(0).toUpperCase() + category.slice(1)}`] += transaction.amount;
          return result;
        },
        { deposits: [], withdrawals: [], sumOfDeposits: 0, sumOfWithdrawals: 0 }
      );
    }
  }
 
}
</script>

<style lang="scss" scoped>

  .select-label{
    display: flex;
    align-items: center;
  }
  #finance-indicators{
    display: flex;
    align-items: center;
    span{
      font-weight: bold;
      font-size: 16px;
    }

  }
</style>