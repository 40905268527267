<template>
  <div>
    <b-sidebar
      id="sidebar-change-name"
      backdrop-variant="primary"
      backdrop
      v-model="visibility"
      :right="true"
      title="Adicionar nota"
    >
      <b-card>
        <div class="form-block">
          <hr data-content="Preencha abaixo" class="hr-text">
          <b-form-group>
            <div class="mt-2 d-flex text-center">
              <b-form-input icon-no-border placeholder="Insira titulo" class="input-rounded-full" v-model="note.title" />
            </div>
          </b-form-group>
          <b-form-group>
            <div class="mt-2 d-flex text-center">
              <b-form-input icon-no-border placeholder="Insira conteúto" class="input-rounded-full" v-model="note.content" />
            </div>
          </b-form-group>
          <b-form-group>
            <b-form-select v-model="note.priority" size="lg" class="mt-3 mb-3">
              <b-form-select-option :value="null">Prioridade</b-form-select-option>
              <b-form-select-option :value="'HIGH'">ALTA</b-form-select-option>
              <b-form-select-option :value="'NORMAL'">NORMAL</b-form-select-option>
            </b-form-select>
          </b-form-group>
        </div>
        <b-alert
          :show="dismissCountDown"
          fade
          variant="warning"
          @dismiss-count-down="countDownChanged"
          class="p-1"
        >
          {{message}}
        </b-alert>
        <div class="mt-2">
          <b-button block @click="alterTransaction()" variant="gradient-primary" >Alterar</b-button>
        </div>
      </b-card>
      <div class="loading-bg" v-if="loading">
        <div class="loading">
          <div class="effect-1 effects"></div>
          <div class="effect-2 effects"></div>
          <div class="effect-3 effects"></div>
        </div>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar,
  BCard,
  BButton,
  BRow,
  BFormInput,
  BFormGroup,
  BSpinner,
  BAlert,
  BFormSelect,
  BFormSelectOption
  } from 'bootstrap-vue'
import gql from 'graphql-tag'
import {_} from 'vue-underscore'
export default {
  components: {
    BSidebar,
    BButton,
    BCard,
    BRow,
    BFormInput,
    BFormGroup,
    BSpinner,
    BAlert,
    BFormSelect,
    BFormSelectOption,
    underscore:_
  },
  data() {
    return {
      visibility:false,
      loading:false,
      newName:null,
      dismissSecs:2,
      dismissCountDown:0,
      showDismissibleAlert: false,
      message: '',
      note:{title:null, content:null,priority:null},
    }
  },
  methods:{
    get_player() {
      const user = window.localStorage.getItem('userData')
      return JSON.parse(user)
    },    
    toogleV(){
        this.visibility = !this.visibility
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert(){
      this.dismissCountDown = this.dismissSecs
    },
    alterTransaction(){
      if(_.contains(this.note, null)){
        this.message = "Preencha todos os campos";
        this.showAlert();
      }else{
        this.loading = true;
        let userId = this.$route.params.clientId
        this.$apollo.mutate({
          mutation: gql`mutation (
            $user_id: ID!,
            $title: String,
            $content: String,
            $priority: String,
            $created_by: String
          ) {
            createUserNote(
              user_id: $user_id,
              title: $title,
              content: $content,
              priority: $priority,
              created_by: $created_by
            ){
              id
            }
          }`,
          variables: {
            user_id: userId,
            title: this.note.title,
            content: this.note.content,
            priority: this.note.priority,
            created_by: this.get_player().username
          },
          client: "identityClient"
        }).then((data) => {
          // Result
          this.loading = false;
          this.toogleV();
          this.$emit('refreshNotes',true)
        }).catch((error) => {
          console.error(error)
        })
      }
    }
  }
}
</script>

<style>
  .b-sidebar-right{
      background: #161d31!important;
      width: 450px;
  }
  #sidebar-change-name___title__{
    text-align: center;
    width: 100%;
    display: block;
  }
  .hr-text {
    position: relative;
    border: none;
    height: 1px;
    background: #999;
}

.hr-text::before {
    content: attr(data-content);
    display: inline-block;
    background: #fff;
    font-weight: bold;
    font-size: 1rem;
    color: #999;
    border-radius: 30rem;
    padding: 0.2rem 1rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.pointer label{
  cursor: pointer;
}
.loading-bg{
  background: rgb(16,170,228, 50%);
  width: 100%;
  height: 100%;
  top: 0;
  position: absolute;
  z-index: 3;
}
</style>