<template>
  <b-card title="Lista de promoçoes">
    <b-table
      v-if="all_wallet_types"
      :items="items"
      :fields="fields"
      striped
      responsive
      class="data-table"
      :key="k"
    >
      <template #cell(status)="data">
        <b-badge :variant="status[1][data.value]">
          {{ status[0][data.value]}}
        </b-badge>
      </template>
      <template #cell(actions)="data">
        <b-button id="release-promo" v-if="data.index == 0 && checkTime(data.item.created_at)" class="act-button" variant="outline-danger" @click="rollBackBonus(data.item.transaction_id,data.item.id)">Desfazer</b-button>
      </template>
    </b-table>
  </b-card>
</template>
<script>
import {
  BTable, BFormCheckbox, BButton, BCard, BRow, BCol, BBadge,BCardText, BLink
} from 'bootstrap-vue'
import gql from 'graphql-tag'

import {_} from 'vue-underscore';

export default {
  components: {
    BTable,
    BButton,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BCardText,
    BBadge,
    BLink,
    underscore: _,
    gql
  },
  props:['items', 'contextActions', 'addInfo'],
  data() {
    return {
      fields: ['transaction_id', 'promotion.name','promotion.title','value_released','released_by',{"key":'promotion.wallet_type_id', "label":"Carteira", "formatter": "walletName"}, {key:'created_at', label: 'Criado em', formatter:'dateFormatter'}, 'actions'],
      k:0,
      localInfo:null,
    }
  },
  apollo:{
    all_wallet_types: {
      query: gql`query{
        all_wallet_types{
          id,
          code,
        }
      }`,
      client: 'walletClient'
    }
  },
  watch:{
    addInfo: function(){
      this.localInfo = this.addInfo;
    },
  },
  methods:{
    checkAlready(id){
      if(this.localInfo != null && this.localInfo.length >0){
        return this.localInfo.filter(a => a.id == id).length
      }
    },
    addPromotionGroup(item){
      if(this.localInfo == null){
        this.$emit('addPromotionGroup', {'g_id': item.id, 'g_arr' : [item]})
      }else{
        this.localInfo = this.localInfo.concat(item)
        this.$emit('addPromotionGroup', {'g_id': item.id, 'g_arr' : this.localInfo})
      }
      this.k++;
    },
    removePromotionGroup(item){
      this.localInfo = this.localInfo.filter(r => r.id != item.id)
      this.$emit('removePromotionGroup',{'g_id': item.id, 'g_arr' : this.localInfo})
      this.k++;
    },
    markItem(e,l,b){
      if(e.id != this.idToMark){
        this.localInfo = this.localInfo.concat(e)
        e.selected = this.marker;
        this.k++;
      }
    },
    openRefModal(){
      this.$emit('showGroupModal',true)
    },
    walletName(e){
      return this.all_wallet_types.filter(w => w.id == e)[0].code
    },
    boolName(e){
      let r = ['Não','Sim']
      return r[e]
    },
    dateFormatter(value){
      return this.$datter.convert(value);
    },
    rollBackBonus(transaction_id,release_entry){
      var el = document.getElementById("release-promo");
      el.style.display = "none";
      this.toRollBack = transaction_id;
      this.releaseEntry = release_entry;
      this.$apollo.mutate({
        mutation: gql`mutation ($transaction_id: ID!,$release_entry: ID!){
        rollBackMovement(
          bonus_transaction_id: $transaction_id,
          reference_id: $release_entry,
          )
        }`,
        variables: {
          transaction_id: this.toRollBack,
          release_entry: this.releaseEntry,
        },
        client: "walletClient"
      }).then((data) => {
        if(data.data.rollBackMovement == true){
          this.$apollo.mutate({
            mutation: gql`mutation ($id: ID!,$release_status: String){
            changePromoReleaseStatus(
              id: $id,
              release_status: $release_status,
              ){
                id
              }
            }`,
            variables: {
              id: this.releaseEntry,
              release_status: "ROLLEDBACK",
            },
            client: "identityClient"
          }).then((data) => {
            alert("Promoção revertida");
            this.$router.go(this.$router.currentRoute)
          }).catch((error) => {
            console.error(error)
          })
        }
      }).catch((error) => {
        console.error(error)
      })
    },
    checkTime(time){
      let now = new Date();
      let promo = this.$datter.rawDate(time);
      let min = parseInt(Math.abs(promo.getTime() - now.getTime()) / (1000 * 60) % 60);
      return (min <15);
    }
  },
  created(){
    if(this.contextActions != null){
      this.fields.push('actions')
    }
    this.localInfo = this.addInfo;
  }
}
</script>

<style>

  .floater{
    /* position: absolute; */
    z-index: 99;
  }
  .data-table tbody{
    padding-bottom: 30px;
  }
  .b-fon{
    width: 25px;
    height: 25px;
  }
</style>