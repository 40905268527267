<template>
  <div>
    <b-sidebar
      id="sidebar-change-name"
      backdrop-variant="primary"
      backdrop
      v-model="visibility"
      :right="true"
      title="Troca de nickname"
    >
      <b-card>
        <div class="form-block">
          <hr data-content="Preencha abaixo" class="hr-text">
          <b-form-group>
            <div class="mt-2 d-flex text-center">
              <b-form-input icon-no-border @keydown.space.prevent placeholder="Insira novo nome" class="input-rounded-full" v-model="newName" />
            </div>
          </b-form-group>
        </div>
        <b-alert
          :show="dismissCountDown"
          fade
          variant="warning"
          @dismiss-count-down="countDownChanged"
          class="p-1"
        >
          {{message}}
        </b-alert>
        <div class="mt-2">
          <b-button block @click="alterTransaction()" variant="gradient-primary" >Alterar</b-button>
        </div>
      </b-card>
      <div class="loading-bg" v-if="loading">
        <div class="loading">
          <div class="effect-1 effects"></div>
          <div class="effect-2 effects"></div>
          <div class="effect-3 effects"></div>
        </div>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar,
  BCard,
  BButton,
  BRow,
  BFormInput,
  BFormGroup,
  BCardText,
  BSpinner,
  BAlert
  } from 'bootstrap-vue'
import gql from 'graphql-tag'
export default {
  components: {
    BSidebar,
    BButton,
    BCard,
    BRow,
    BFormInput,
    BFormGroup,
    BCardText,
    BSpinner,
    BAlert
  },
  data() {
    return {
      visibility:false,
      loading:false,
      newName:null,
      dismissSecs:2,
      dismissCountDown:0,
      showDismissibleAlert: false,
      message: ''
    }
  },
  methods:{
    toogleV(){
        this.visibility = !this.visibility
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert(){
      this.dismissCountDown = this.dismissSecs
    },
    alterTransaction(){
      if(this.newName == null){
        this.message = "Nome não preenchido";
        this.showAlert();
      }else{
        this.loading = true;
        let document = this.$parent.$apolloData.data.user_with_all_relations.documents.filter(w => w.type == "NICKNAME")[0]
        this.$apollo.mutate({
          mutation: gql`mutation (
            $id: ID!,
            $value: String,
          ) {
            updateUserDocument(
              id: $id,
              value: $value,
            ){
              id
            }
          }`,
          variables: {
            id: document.id,
            value: this.newName,
          },
          client: "identityClient"
        }).then((data) => {
          // Result
          this.loading = false;
          this.toogleV();
          this.$emit('refreshUserData',true)
        }).catch((error) => {
          console.error(error)
        })
      }
    }
  }
}
</script>

<style>
  .b-sidebar-right{
      background: #161d31!important;
      width: 450px;
  }
  #sidebar-change-name___title__{
    text-align: center;
    width: 100%;
    display: block;
  }
  .hr-text {
    position: relative;
    border: none;
    height: 1px;
    background: #999;
}

.hr-text::before {
    content: attr(data-content);
    display: inline-block;
    background: #fff;
    font-weight: bold;
    font-size: 1rem;
    color: #999;
    border-radius: 30rem;
    padding: 0.2rem 1rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.pointer label{
  cursor: pointer;
}
.loading-bg{
  background: rgb(16,170,228, 50%);
  width: 100%;
  height: 100%;
  top: 0;
  position: absolute;
  z-index: 3;
}
</style>